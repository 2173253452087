:root {
	/*_________________________________*/

	/*COLOR MANAGEMENT*/

	/*_________________________________*/

	--base_background: #ffffff;
	--base_top-bar-navigation: #000000;
	--base_secondary-navigation-bar: #f2f2f3;
	--base_wallet-sidebar-and-popup: #f3f3f3;
	--base_footer: #000000;

	--labels_important-active-labels-text-graphics: #000000;
	--labels_secondary-inactive-label-text-graphics: #808080;
	--calculated_important-border: #000000b3;
	--calculated_secondary-border: #00000054;
	--calculated_footer_border: #ffffff26;
	--calculated_super-pale_label-text-graphics: #00000026;
	--labels_fields: #f0f1f1;
	--labels_inactive-button: #535353;

	--trading_selling-related-elements: #ee4036;
	--trading_buying-related-elements: #00a69c;

	--specials_buttons-links-and-highlights: #0066b4;
	--specials_chat-messages: #98ccb2;
	--specials_my-username-in-chat: #ffff00;
	--specials_checks-okay-done: #008000;
	--specials_pending-waiting-caution: #f6921e;
	--specials_notifications-alerts-warnings: #ed1c24;
	--specials_quick-trade-gradient: #00000000;

	/*_________________________________*/

	/*Calculated colors*/

	/*_________________________________*/

	--calculated_tag-background: #ffffffaa;
	--calculated_background-overlay: #ffffffaa;
	--calculated_stake-table_highlight: #ffffffaa;
	--calculated_trade-block_highlight: #000000fa;
	--calculated_market-card_gradient_background: #ffffff;
	--calculated_trading_selling-subtracted-bg: #ee403688;
	--calculated_trading_selling-added-bg: #ee403688;
	--calculated_trading_selling-best-subtracted: #ee403688;
	--calculated_trading_buying-subtracted-bg: #00a69c88;
	--calculated_trading_buying-added-bg: #00a69c88;
	--calculated_trading_buying-best-subtracted: #00a69c88;
	--calculated_public-sale_buying-zeros: #ee403688;
	--calculated_public-sale_selling-zeros: #00a69c88;
	--calculated_trading_selling-sparkline-fill: #ee40364d;
	--calculated_trading_buying-sparkline-fill: #00a69c4d;
	--calculated_trading_selling-orderbook-bar: #ee40361f;
	--calculated_trading_buying-orderbook-bar: #00a69c1f;
	--calculated_trading_buying-related-text: #000000;
	--calculated_trading_selling-related-text: #ffffff;
	--calculated_base_modal-overlay: #ffffffbf;
	--calculated_landing_background: #ffffff00;
	--calculated_landing_markets_table: #fffffff0;
	--calculated_specials_highlight-box: #0066b41a;
	--calculated_base_top-bar-navigation_text: #ffffff;
	--calculated_base_top-bar-navigation_text-inactive: #ffffffb3;
	--calculated_base_footer_text: #ffffff;
	--calculated_trad-view_watermark: #0000001a;
	--calculated_trad-view_axis: #00000080;
	--calculated_trad-view_text: #000000cc;
	--calculated_specials_notifications-alerts-inactive: #ed1c24bf;
	--calculated_specials_notifications-alerts-text: #ffffff;
	--calculated_specials_notifications-pending-text: #ffffff;
	--calculated_specials_notifications-success-text: #ffffff;
	--calculated_notification-pending-disabled: #f6921e4d;
	--calculated_notifications-blue-disabled: #0066b44d;

	/*_________________________________*/

	/*_____Remaining_____*/
	--dark-accordion-arrow-active: #ffffff;

	/*______coins______*/
	--coin-eur: #06773d;
	--coin-eur-text: #ffffff;
	--coin-usd: #01592e;
	--coin-usd-text: #ffffff;
	--coin-jpy: #e0dfc7;
	--coin-jpy-text: #000000;
	--coin-gbp: #482e6e;
	--coin-gbp-text: #ffffff;
	--coin-chf: #ed1c24;
	--coin-chf-text: #ffffff;
	--coin-cad: #d7ce58;
	--coin-cad-text: #000000;
	--coin-aud: #19441f;
	--coin-aud-text: #ffffff;
	--coin-nzd: #b3498c;
	--coin-nzd-text: #ffffff;
	--coin-zar: #d8d3a0;
	--coin-zar-text: #000000;
	--coin-hkd: #f48f9b;
	--coin-hkd-text: #000000;
	--coin-krw: #f9ee02;
	--coin-krw-text: #000000;
	--coin-cny: #ec008c;
	--coin-cny-text: #ffffff;
	--coin-sgd: #720f15;
	--coin-sgd-text: #ffffff;
	--coin-btc: #f7941d;
	--coin-btc-text: #000000;
	--coin-eth: #3e4ea1;
	--coin-eth-text: #ffffff;
	--coin-ltc: #bcbec0;
	--coin-ltc-text: #000000;
	--coin-bch: #8dc541;
	--coin-bch-text: #000000;
	--coin-xrp: #414042;
	--coin-xrp-text: #ffffff;
	--coin-xht: #000000;
	--coin-xht-text: #ffffff;
	--coin-eos: #58595b;
	--coin-eos-text: #ffffff;
	--coin-bnb: #f1ba2a;
	--coin-bnb-text: #000000;
	--coin-link: #1b75bc;
	--coin-link-text: #ffffff;
	--coin-trx: #a22d25;
	--coin-trx-text: #ffffff;
	--coin-ada: #3667b2;
	--coin-ada-text: #ffffff;
	--coin-matic: #2aaae2;
	--coin-matic-text: #ffffff;
	--coin-neo: #bcbec0;
	--coin-neo-text: #000000;
	--coin-etc: #009444;
	--coin-etc-text: #000000;
	--coin-xlm: #808285;
	--coin-xlm-text: #ffffff;
	--coin-xmr: #f15a29;
	--coin-xmr-text: #ffffff;
	--coin-zec: #fbb040;
	--coin-zec-text: #000000;
	--coin-one: #939598;
	--coin-one-text: #ffffff;
	--coin-pax: #163526;
	--coin-pax-text: #ffffff;
	--coin-usdc: #4d7d91;
	--coin-usdc-text: #ffffff;
	--coin-usdt: #2bb673;
	--coin-usdt-text: #ffffff;
	--coin-vet: #454c7d;
	--coin-vet-text: #ffffff;
	--coin-btt: #c49a6c;
	--coin-btt-text: #ffffff;
	--coin-qtum: #96daf7;
	--coin-qtum-text: #000000;
	--coin-algo: #594a42;
	--coin-algo-text: #ffffff;
	--coin-rvn: #662d91;
	--coin-rvn-text: #ffffff;
	--coin-iota: #603913;
	--coin-iota-text: #ffffff;
	--coin-dash: #417ec1;
	--coin-dash-text: #ffffff;
	--coin-bsv: #a09032;
	--coin-bsv-text: #ffffff;
	--coin-ont: #00a79d;
	--coin-ont-text: #ffffff;
	--coin-atom: #796184;
	--coin-atom-text: #ffffff;
	--coin-celr: #726658;
	--coin-celr-text: #ffffff;
	--coin-omg: #8e7fbb;
	--coin-omg-text: #ffffff;
	--coin-hot: #8b5e3c;
	--coin-hot-text: #ffffff;
	--coin-tusd: #002868;
	--coin-tusd-text: #ffffff;
	--coin-dai: #f3c27a;
	--coin-dai-text: #000000;
	--coin-ama: #fa2902;
	--coin-ama-text: #ffffff;
	--coin-xtz: #2c7df7;
	--coin-xtz-text: #ffffff;
	--coin-doge: #c3a634;
	--coin-doge-text: #ffffff;
	--coin-uni: #ff4dab;
	--coin-uni-text: #ffffff;
	--coin-dot: #d80579;
	--coin-dot-text: #ffffff;
}
